import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import Top from '../includes/topping';
import { Link } from "react-router-dom";
import Loader from '../includes/loader';
import API from '../../jsx/backend';
import Error from '../includes/error';
import Greeting from '../includes/greeting';
import Updates from '../sections/updates';

const Recover = () => {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const params = useParams();
    const  email = params.email;
    const  secret = params.secret;
    const [success, setSuccess] = useState(false);
    const [fail, setFail] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (window.sessionStorage.getItem('token')) {
            window.location = "/";   
        }
    }, []);

    const handleRecover = (e) => {
        e.preventDefault();
        setLoading(true);
        const passwordFormDataNew = new FormData();
        passwordFormDataNew.append("password",password);
        passwordFormDataNew.append("email",email);
        passwordFormDataNew.append("secret",secret);
        passwordFormDataNew.append("action","recover");
        
        API.post(`/`, passwordFormDataNew)
        .then((response) => {
            switch(response.data.status){
                case "1":
                  setSuccess(true);
                  setLoading(false);
                  setTimeout(function(){
                      window.location = "/auth/login/";
                    }, 3000);
                break;
              
                default:
                  setFail(true);
                  setLoading(false);
                  setTimeout(function(){
                      window.location = "/auth/reset/";
                    }, 3000);
              }
                      })
                      .catch((error) => { 
                          setError(true);
                          setLoading(false);
                      })}

return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>Recover Account: { email } - SM Portal</title>
</Helmet>

<div className="munyori-section munyori-container">
<form onSubmit={handleRecover}>
  {error
? <>
<Error />
</>
: <>
{success
? <div className="munyori-center munyori-text-green">
<h1>OPERATION SUCCESSFUL</h1>
<br />
<i className="fas fa-spinner munyori-spin fa-7x"></i>
</div>
: <>
{fail
? <div className="munyori-center munyori-text-red">
<h1>OPERATION FAILED</h1>
<br />
<i className="fas fa-spinner munyori-spin fa-7x"></i>
</div>
: <>
{!loading
?  <div className="munyori-container munyori-row-padding">
<div className="munyori-half munyori-center munyori-white munyori-card munyori-padding" style={{borderRadius: "10px"}}>
  <h4><Greeting />, SET NEW PASSWORD</h4>
  <hr />
  <label for="password" className="munyori-left">NEW PASSWORD</label>
  <input type="password" id="password" className="munyori-input munyori-border" style={{width: "100%", borderRadius: "10px" }} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="New password..." required />
  <br />
  <input type="submit" className="munyori-input munyori-button munyori-green munyori-border-0" style={{width: "100%", borderRadius: "10px" }} value="SET NEW PASSWORD" />
  </div>
  <div className="munyori-half munyori-padding munyori-center">
<br />
<i className="fas fa-key fa-6x munyori-text-grey"></i>
<br />
  <div className="munyori-container munyori-padding">
  <Link to="/auth/login/"><button type="button" className="munyori-tiny munyori-button munyori-pale-green" style={{width: "100%", borderRadius: "10px" }}>ACCOUNT LOGIN</button></Link>
  </div>
  <div className="munyori-container munyori-padding">
  <Link to="/auth/register/"><button type="button" className="munyori-tiny munyori-button munyori-black" style={{width: "100%", borderRadius: "10px" }}>REGISTER PARENT</button></Link>
  </div>
  </div>
  </div>
: <>
  <Loader />
  </>
  }
    </>
}
</>
}
</>
}
</form>
</div>
<br />
<div className="munyori-container">
<div class="munyori-heading munyori-opacity">
<h5><span>LATEST SCHOOL UPDATES</span></h5>
</div>
<Updates />
</div>
<Top />
</div>
);}

export default Recover;