import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import API from '../../jsx/backend';
import Loader from '../includes/loader';

function Updates() {
    const [tip, setTips] = useState('');
    const [loading, setLoading] = useState(true);

useEffect(() => {
        API.get(`?posts=latest`)
        .then((res) => {
        setTips(res.data.posts);
        setLoading(false);
        })
        .catch((error) => {
        setTips("");
        setLoading(false);
        })}, []);

return (
<div className="munyori-row-padding" style={{paddingBottom: "50px"}}>
{!loading
? <>
{tip
? <>
{tip.map((post) => (
<div className="munyori-quarter" style={{borderRadius: "10px 10px 0 0"}}>
 <br />   
<Link to={`/updates/${post.url }/`} style={{textDecoration: "none"}} title={post.description }>
<div className="munyori-card" style={{
      backgroundImage: 'url(' + post.image + ')',
      height: "auto", backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "100% 100%", borderRadius: "10px 10px 0 0"
    }}>
<div className="munyori-padding munyori-display-container" style={{backgroundColor: "#ffffffe3 ", minHeight: "350px", borderRadius: "10px 10px 0 0"}}>
<h3 className="munyori-display-top munyori-center">{ post.title  }</h3>
</div>
<button className="munyori-btn munyori-green" style={{width: "100%"}}>READ MORE</button>
</div>
</Link>
</div>
))}
</>
: <h4 className="munyori-center" style={{paddingTop: "50px"}}>We have no post suggestions!</h4>
}
</>
: <Loader />
}
</div>
);}

export default Updates;