import React, { useState, useEffect } from 'react';
import {Helmet} from "react-helmet";
import Loader from '../includes/loader';
import Error from '../includes/error';
import API from '../../jsx/backend';
import Top from '../includes/topping';
import { useParams } from "react-router-dom";

const Post = () => {
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
const [title, setTitle] = useState('Please Wait');
const [content, setContent] = useState('No Content');
const [notice, setNotice] = useState('No Content');
const params = useParams();

useEffect(() => {
API.get(`?posts=read&post=${params.post}`)
.then((res) => {
setTitle(res.data.title);
setContent(res.data.content);
setNotice(res.data.notice);
setLoading(false);
})
.catch((error) => {
setTitle('Not Responding');
setLoading(false);
setError(true);
})}, [params.post]);

return (
<div style={{minHeight: "95VH", paddingTop: "60px"}}>
<Helmet>
<title>{ title } - SM Portal</title>
</Helmet>

{loading
? <div className="munyori-section">
  <div className="munyori-container">
  <Loader />
  </div>
  </div>
: <div className="munyori-section">
{error
? <div className="munyori-container">
  <Error />
  </div>
: <>
<div className="munyori-container munyori-row-padding">
<div className="munyori-twothird munyori-padding">
<h1 className="munyori-center">{ title }</h1>
<hr />
<div className="munyori-justify" dangerouslySetInnerHTML={{__html: content}}></div>
</div>
<div className="munyori-third munyori-padding munyori-border" style={{borderRadius: "10px", top: "70px", position: "sticky"}}>
<h1 className="munyori-center">PUBLIC NOTICE</h1>
<hr />
<div className="munyori-justify" dangerouslySetInnerHTML={{__html: notice}}></div>
</div>
</div>
<br />
</>
}
</div>
}
<Top />
</div>
);}

export default Post;